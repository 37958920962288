import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import Dropzone from "react-dropzone";

import { soloSettingsState, boardsState } from "../../atoms/settings.atom";
import { apiGetSingleUser } from "../../api/members";
import { apiGetActiveBoards } from "../../api/backend.options";
import firebase from "../../firebase/firebase";

import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";

import Toggle from "../Shared/Toggle";
import DropDown from "../Shared/DropDown/DropDown";
import PasswordResetModal from "../Modals/PasswordResetModal";
import InsertBoard from "./InsertItem";

const MemberForm = ({ action, user, server }) => {
  const soloSettings = useRecoilValue(soloSettingsState);
  const [boards, setBoards] = useRecoilState(boardsState);
  const [memberOf, setMemberOf] = useState([]);
  const [adminRole, setAdminRole] = useState(false);
  const [managerRole, setManagerRole] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [photoUrl, setPhotoUrl] = useState(
    "https://www.fillmurray.com/g/140/100"
  );
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [title, setTitle] = useState("");
  const [votingRole, setVotingRole] = useState(false);
  const [active, setActive] = useState(true);
  const [email, setEmail] = useState("");
  const [district, setDistrict] = useState(0);
  const [allowSave, setAllowSave] = useState(true);
  const [resetModal, setResetModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const boardResult = await apiGetActiveBoards(
        soloSettings.backendServerUrl
      );
      if (Array.isArray(boardResult)) {
        setBoards(boardResult);
      }
    }
    if (Object.entries(soloSettings).length !== 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soloSettings]);

  useEffect(() => {
    async function fetchData() {
      const result = await apiGetSingleUser(server, user);
      // console.log(result);
      setAdminRole(result.adminRole);
      setFirstName(result.firstName);
      setLastName(result.lastName);
      setPhotoUrl(result.photoUrl);
      setTitle(result.title);
      setVotingRole(result.votingRole);
      setActive(result.active);
      setEmail(result.email);
      setManagerRole(result.managerRole);
      setDistrict(result.district);
      if (result.memberOf) {
        setMemberOf(result.memberOf);
      }
    }
    if (user) {
      fetchData();
    } else {
      setAllowSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (pass1.length >= 8 && pass2.length >= 8) {
      if (pass1 === pass2) {
        setAllowSave(true);
      }
    }
  }, [pass1, pass2]);

  function handleUserPhoto(files) {
    try {
      var storageRef = firebase
        .storage()
        .ref(`user-photos/${soloSettings._id}/${files[0].name}`);
      var status = storageRef.put(files[0]);
      status.on(
        "state_changed",
        function progress(snapshot) {
          var progress = ~~(
            (snapshot.bytesTransferred / snapshot.totalBytes) *
            100
          );
          if (progress > 0) {
            // toast.info(`Uploading Progress ${progress}%`, {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            //   hideProgressBar: true,
            // });
          }
        },
        function error(err) {
          console.log(err);
          // toast.error("Error During Upload", {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });
        },
        function complete() {
          // get url of file
          status.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setPhotoUrl(downloadURL);
          });
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  function handleBoard({ boardId, boardName }) {
    if (memberOf.length === 0) {
      setMemberOf([
        {
          boardId,
          boardName,
          adminRole: false,
          votingRole: false,
          bypassTimer: false,
        },
      ]);
    } else {
      setMemberOf((prevState) => [
        ...prevState,
        {
          boardId,
          boardName,
          adminRole: false,
          votingRole: false,
          bypassTimer: false,
        },
      ]);
    }
  }

  function handleBoardOptions(index, key, value) {
    let tempArray = Array.from(memberOf);
    const [removed] = tempArray.splice(index, 1);
    let newData = {
      ...removed,
      [key]: value,
    };
    tempArray.splice(index, 0, newData);
    setMemberOf(tempArray);
  }

  function handleBoardRemove(index) {
    let tempArray = Array.from(memberOf);
    tempArray.splice(index, 1);
    setMemberOf(tempArray);
  }

  function handleCancel() {
    action({ type: "cancel" });
  }

  function handlePasswordReset(value) {
    action({ type: "password", password: value, id: user });
    setResetModal(false);
  }

  function handleSaveUser() {
    let updatedData;
    if (user) {
      updatedData = {
        adminRole,
        managerRole,
        firstName,
        lastName,
        photoUrl,
        title,
        votingRole,
        active,
        email,
        district,
        memberOf,
      };
    } else {
      updatedData = {
        adminRole,
        managerRole,
        firstName,
        lastName,
        photoUrl,
        title,
        votingRole,
        active,
        email,
        district,
        password: pass1,
        memberOf,
      };
    }

    action({ type: "save", data: updatedData, id: user });
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col divide-y divide-gray-500 space-y-4">
        <div className="flex-grow space-y-6">
          <div className="mt-2 xl:mt-4 2xl:mt-6 grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <h3 className="text-base xl:text-lg leading-6 font-medium text-gray-900">
                Profile
              </h3>
            </div>
            {/* Profile Image */}
            <div className="col-span-12">
              <div className="flex items-center space-x-2 2xl:space-x-4">
                <img
                  className="inline-block w-20 h-20 2xl:h-32 2xl:w-32 rounded-full"
                  src={photoUrl}
                  alt=""
                />

                <Dropzone
                  onDrop={(e) => handleUserPhoto(e)}
                  multiple={false}
                  accept="image/png, image/jpeg, image/jpg"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <button
                        type="button"
                        className="bg-gray-200 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Change
                      </button>
                      <input {...getInputProps()} />
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>

            {/* Passwords */}
            {user ? (
              <div className="col-span-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-indigo-500 text-gray-50 rounded-md hover:bg-opacity-70 transform duration-300"
                  onClick={() => setResetModal(true)}
                >
                  Reset Password
                </button>
              </div>
            ) : (
              <>
                <div className="col-span-4">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Password
                    <span className="ml-2 font-light text-gray-600 text-xs">
                      Minimum 8 characters
                    </span>
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex rounded-md shadow-sm">
                      <input
                        type="password"
                        id="pass1"
                        required
                        value={pass1}
                        className="flex-1 block p-2 w-full h-8 shadow-sm border border-gray-400 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm "
                        onChange={(e) => setPass1(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-4">
                  <label
                    htmlFor="testcode"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Confirm Password
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex rounded-md shadow-sm">
                      <input
                        type="password"
                        id="pass2"
                        required
                        value={pass2}
                        className="flex-1 block w-full p-2 h-8 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-400"
                        onChange={(e) => setPass2(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {pass1.length >= 8 && pass2.length >= 8 && (
                  <div className="col-span-4 flex items-end">
                    {pass1 !== pass2 ? (
                      <p className="bg-red-600 rounded-full px-6 py-1">
                        Passwords Don&#39;t Match
                      </p>
                    ) : (
                      <BsFillCheckCircleFill className="w-5 h-5 text-green-600" />
                    )}
                  </div>
                )}
              </>
            )}

            {/* Personal Information */}

            <div className="col-span-12">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Personal Information
              </h3>
            </div>

            <div className="col-span-4">
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                First name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  required
                  value={firstName}
                  className="max-w-lg block w-full p-2 h-8 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-400 rounded-md"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>

            <div className="col-span-4">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Last name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  required
                  value={lastName}
                  className="max-w-lg block w-full p-2 h-8 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-400 rounded-md"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-span-4">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Title
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  required
                  value={title}
                  className="max-w-lg block w-full p-2 h-8 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-400 rounded-md"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="col-span-4">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  required
                  value={email}
                  className="max-w-lg block w-full p-2 h-8 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-400 rounded-md"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="col-span-4">
              <DropDown
                heading="District"
                value={district}
                action={setDistrict}
              />
            </div>
            {/* Board Management */}
            <div className="col-span-12">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Member Of
              </h3>
            </div>
            <div className="col-span-3">
              <InsertBoard
                boards={boards}
                addBoard={handleBoard}
                memberOf={memberOf}
              />
            </div>
            <div className="col-span-9">
              {memberOf.map(
                (
                  { boardId, boardName, adminRole, votingRole, bypassTimer },
                  index
                ) => {
                  // TODO add default seat dropdown
                  return (
                    <div
                      key={boardId}
                      className="flex justify-between items-center bg-gray-200 border border-gray-600 rounded-md p-4 mb-2"
                    >
                      <p className="grow font-medium">{boardName}</p>
                      <div className="flex items-center">
                        {soloSettings?.memberTimer && (
                          <div className="mr-8 flex flex-col items-center">
                            <input
                              id={index}
                              name="bypassTimer"
                              type="checkbox"
                              checked={bypassTimer}
                              className="h-4 w-4 text-indigo-600 bg-gray-50 rounded mb-1 focus:outline-none"
                              onChange={() =>
                                handleBoardOptions(
                                  index,
                                  "bypassTimer",
                                  !bypassTimer
                                )
                              }
                            />
                            <p className="text-sm">Bypass Timer</p>
                          </div>
                        )}
                        <div className="mr-8 flex flex-col items-center">
                          <input
                            id={index}
                            name="adminRole"
                            type="checkbox"
                            checked={adminRole}
                            className="h-4 w-4 text-indigo-600 bg-gray-50 rounded mb-1 focus:outline-none"
                            onChange={() =>
                              handleBoardOptions(index, "adminRole", !adminRole)
                            }
                          />
                          <p className="text-sm">Admin</p>
                        </div>
                        <div className="mr-8 flex flex-col items-center">
                          <input
                            id={index}
                            name="votingRole"
                            type="checkbox"
                            checked={votingRole}
                            className="h-4 w-4 text-indigo-600 bg-gray-50 rounded mb-1 focus:outline-none"
                            onChange={() =>
                              handleBoardOptions(
                                index,
                                "votingRole",
                                !votingRole
                              )
                            }
                          />
                          <p className="text-sm">Voting</p>
                        </div>
                        <button onClick={() => handleBoardRemove(index)}>
                          <RiDeleteBin6Line className="text-delete h-5 w-5 hover:text-red-500 transform duration-300" />
                        </button>
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            {/* Options */}

            <div className="col-span-12">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Options
              </h3>
            </div>

            <div className="col-span-12 grid grid-cols-3">
              {/* "bg-indigo-600", Off: "bg-gray-200"*/}
              <div className="flex flex-col space-y-1">
                <Toggle value={managerRole} action={setManagerRole} />
                <span className="flex-grow flex flex-col" id="toggleLabel">
                  <span className="text-sm font-medium text-gray-900">
                    Manager Role
                  </span>
                  <span className="text-sm leading-normal text-gray-500 pb-4">
                    This user will be in charge of running the meeting.
                  </span>
                </span>
              </div>
              {user && (
                <div className="flex flex-col space-y-1">
                  <Toggle value={active} action={setActive} />
                  <span className="flex-grow flex flex-col" id="toggleLabel">
                    <span className="text-sm font-medium text-gray-900">
                      User Status
                    </span>
                    <span className="text-sm leading-normal text-gray-500">
                      Enable / Disable User
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="pt-2 xl:pt-3 2xl:pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-none transform duration-300"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
              {allowSave && (
                <button
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-opacity-70 focus:outline-none transform duration-300"
                  onClick={() => handleSaveUser()}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {resetModal && (
        <PasswordResetModal
          isVisible={setResetModal}
          resetPassword={handlePasswordReset}
        />
      )}
    </div>
  );
};

export default MemberForm;
