import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import { useSocket } from "../hooks/useSocket";
import {
  deleteSessionStorage,
  getSessionStorage,
  getLocalStorage,
} from "../helpers/storage";
import { soloSettingsState, boardsListState } from "../atoms/settings.atom";
import { setupMenuChoiceState } from "../atoms/globals.atom";
import { managerUserState } from "../atoms/user.atom";
import { apiGetBoards } from "../api/backend.options";
import { apiSetupLogout } from "../api/login";

// components
import MenuItem from "../components/MenuItem";
import Members from "../views/Members";
import Meetings from "../views/Meetings";
import Recent from "../views/Recent";
import Options from "../views/Options";
import packageJson from "../../package.json";
import Loading from "../components/Modals/Loading";
import MobileUnSupported from "../components/NavBar/MobileUnSupported";
import SetupLogin from "../components/Login/SetupLogin";
import AV from "../views/AV";
import Plugins from "../views/Plugins";
import CompletedMeetings from "../components/Completed/CompletedMeetings";
import CompletedSpeakers from "../components/Completed/CompletedSpeakers";

const menuOptions = [
  "Members",
  "Meetings",
  "Recent",
  "Options",
  "A/V",
  "Plugins",
  "Completed",
  "Speakers",
];

const SetupPage = () => {
  let history = useHistory();
  const [menu, setMenu] = useRecoilState(setupMenuChoiceState);
  const [soloSettings, setSoloSettings] = useRecoilState(soloSettingsState);
  const [managerUser, setManagerUser] = useRecoilState(managerUserState);
  const setBoards = useSetRecoilState(boardsListState);
  const { sendEvent } = useSocket(soloSettings.backendServerUrl, "/setup");

  useEffect(() => {
    const stored = getSessionStorage("user");
    if (stored) {
      setManagerUser(stored);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData(url) {
      const result = await apiGetBoards(url);
      if (Array.isArray(result)) {
        setBoards(result);
      }
    }
    if (soloSettings) {
      if (soloSettings.backendServerUrl) {
        fetchData(soloSettings.backendServerUrl);
      }
    } else {
      const config = getLocalStorage("config");
      setSoloSettings(config);
      fetchData(config.backendServerUrl);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soloSettings]);

  async function handleMenuChoice(choice) {
    setMenu(choice);
  }

  async function handleLogout() {
    await apiSetupLogout(
      soloSettings.backendServerUrl,
      managerUser._id,
      managerUser.token
    );
    deleteSessionStorage("user");
    setManagerUser();
    history.push("/");
  }

  if (Object.entries(soloSettings).length === 0) {
    return <Loading />;
  }

  if (!managerUser) {
    return <SetupLogin />;
  }

  return (
    <div className="h-screen flex flex-nowrap bg-gray-100">
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-48 xl:w-52 2xl:w-64">
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center justify-between flex-shrink-0 px-4">
                <a href="/">
                  <img
                    className="h-6 2xl:h-10 w-auto"
                    src="/images/LogoBlue.png"
                    alt="AgendaLink"
                  />
                </a>
                <p className="text-white text-base 2xl:text-lg">
                  AgendaLink Solo
                </p>
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
                {menuOptions.map((item, index) => {
                  if (item === "A/V" || item === "Plugins") {
                    if (soloSettings?.hasController) {
                      return (
                        <MenuItem
                          key={index}
                          name={item}
                          index={index}
                          status={menu === index}
                          choose={handleMenuChoice}
                        />
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <MenuItem
                        key={index}
                        name={item}
                        index={index}
                        status={menu === index}
                        choose={handleMenuChoice}
                      />
                    );
                  }
                })}
              </nav>
            </div>

            <button
              className="px-6 py-3 mx-4 mb-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
              onClick={() => handleLogout()}
            >
              Logout
            </button>
            <p className="pl-2 text-white">
              {managerUser?.firstName} {managerUser?.lastName}
            </p>
            <div className="flex justify-between text-white px-2 mb-2">
              <p>v {packageJson.version}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex md:flex-col md:w-0 flex-1 overflow-hidden">
        {/* MAIN AREA */}
        {menu === 0 && <Members />}
        {menu === 1 && <Meetings />}
        {menu === 2 && <Recent />}
        {menu === 3 && <Options sendEvent={sendEvent} />}
        {menu === 4 && <AV sendEvent={sendEvent} />}
        {menu === 5 && <Plugins sendEvent={sendEvent} />}
        {menu === 6 && <CompletedMeetings />}
        {menu === 7 && <CompletedSpeakers />}
      </div>
      <MobileUnSupported />
    </div>
  );
};

export default SetupPage;
