import axios from "axios";

export const apiGetMeetingsSpeakers = async (server, date, board) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    };

    const result = await axios
      .get(
        `${server}/api/session/has-speakers?date=${date}&board=${board}`,
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response;
          return data;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.log("error api speakers apiGetMeetingSpeakers");
    console.log(error);
    return null;
  }
};

export const apiGetSpeakers = async (server, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    };

    const result = await axios
      .get(`${server}/api/session/has-speakers/${id}`, config)
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response;
          return data;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.log("error api speakers apiGetSpeakers");
    console.log(error);
    return null;
  }
};
