import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";

import { userState } from "../../atoms/user.atom";
import { soloSettingsState } from "../../atoms/settings.atom";
import {
  activeDetailsState,
  activeTopicState,
  activeMotionState,
  voterState,
  submittedVotesState,
  motionStatusState,
  sessionBoardState,
  sessionBoardDetails,
} from "../../atoms/session.atom";
import { testModeState } from "../../atoms/globals.atom";

import { calculateTally } from "../../helpers/voting";

import HeadlessDrop from "../Shared/DropDown/HeadlessDrop";

const votingOptions = [
  { id: "majority", name: "Majority" },
  { id: "supermajority", name: "Super Majority" },
  { id: "unanimous", name: "Unanimous" },
  { id: "twothird", name: "Two Thirds" },
  { id: "threefourth", name: "Three Fourths" },
  { id: "fourfifths", name: "Four Fifths" },
];

function VoteModal({ sendEvent }) {
  const user = useRecoilValue(userState);
  const activeDetails = useRecoilValue(activeDetailsState);
  const activeTopic = useRecoilValue(activeTopicState);
  const voters = useRecoilValue(voterState);
  const submittedVotes = useRecoilValue(submittedVotesState);
  const testMode = useRecoilValue(testModeState);
  const activeMotion = useRecoilValue(activeMotionState);
  const motionStatus = useRecoilValue(motionStatusState);
  const sessionBoard = useRecoilValue(sessionBoardState);
  const boardDetails = useRecoilValue(sessionBoardDetails);
  const [manualVote, setManualVote] = useState(false);
  const [buttonFb, setButtonFb] = useState("none");
  const [manualId, setManualId] = useState();
  const [enableEnd, setEnableEnd] = useState(false);
  const soloSettings = useRecoilValue(soloSettingsState);
  const [voteWeight, setVoteWeight] = useState(votingOptions[0]);

  useEffect(() => {
    if (activeDetails) {
      const index = votingOptions.findIndex(
        (e) => e.id === activeDetails.votingOption
      );
      if (index >= 0) {
        setVoteWeight(votingOptions[index]);
      }
    }
  }, [activeDetails]);

  useEffect(() => {
    let exists = submittedVotes.findIndex((e) => e.member === user._id);
    if (exists >= 0) {
      setButtonFb(submittedVotes[exists].vote);
    }
    let endCheck = 0;
    for (const voter of voters) {
      if (voter.loggedIn) {
        endCheck++;
      }
    }
    if (endCheck === submittedVotes.length) {
      setEnableEnd(true);
      if (soloSettings.autoVoteClose && user.managerRole) {
        setTimeout(() => {
          sendEvent("voteEnded", {
            topic: activeTopic,
            motion: activeMotion,
            testMode,
            votingOption: voteWeight.id,
            board: sessionBoard,
          });
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedVotes]);

  function handleEnableManual(member) {
    setManualVote(true);
    setManualId(member);
  }

  function handleManualVote(vote) {
    sendEvent("voteSubmitted", {
      motion: activeMotion,
      vote: vote,
      member: manualId._id,
      topic: activeTopic,
    });
    setManualVote(false);
    setManualId();
  }

  function handleVote(vote) {
    sendEvent("voteSubmitted", {
      motion: activeMotion,
      vote: vote,
      member: user._id,
      topic: activeTopic,
    });
  }

  function handleWeightChange(target, value) {
    setVoteWeight(value);
  }

  return (
    <div className="relative flex h-full w-full justify-start mx-2">
      <div className="flex flex-col h-full w-full items-center justify-start mx-2 divide-y divide-gray-200">
        <div className="mt-0.5 2xl:mt-2 items-center flex flex-nowrap justify-between w-full pb-2 px-2 mb-1 2xl:mb-4">
          <h1 className="leading-6 text-gray-900 text-base 2xl:text-2xl font-semibold">
            Item: {activeDetails?.itemNumber}
          </h1>
          <p className="text-base 2xl:text-2xl text-gray-800">
            Vote for {motionStatus} Motion
          </p>
        </div>
        {user?.managerRole && (
          <div>
            <p className="font-semibold text-lg">
              Vote Tally: {calculateTally(submittedVotes)}
            </p>
          </div>
        )}
        {user?.adminRole && soloSettings.voteColor && (
          <div>
            <p className="font-semibold text-lg">
              Vote Tally: {calculateTally(submittedVotes)}
            </p>
          </div>
        )}

        {user?.managerRole && (
          <div className="w-full">
            <div className="grid grid-cols-8 hd:grid-cols-6 gap-2 2xl:gap-2 2xl:px-8">
              <div className="col-span-8 hd:col-span-6 mt-2 mb-2 2xl:mb-6">
                <HeadlessDrop
                  array={votingOptions}
                  value={voteWeight}
                  change={handleWeightChange}
                  heading="Change Vote Weight"
                />
              </div>
              {voters.map((voter, index) => {
                if (voter.loggedIn) {
                  let vote = "none";
                  // check the submitted votes to see if the voter id exists in the array
                  let exists = submittedVotes.findIndex(
                    (e) => e.member === voter.id
                  );
                  if (exists >= 0) {
                    vote = submittedVotes[exists].vote;
                  }
                  // if it does then set the feedback, if not, then don't
                  return (
                    <div
                      className={clsx(
                        vote === "yea" &&
                          soloSettings.managerShowVote &&
                          "bg-green-600",
                        vote === "nay" &&
                          soloSettings.managerShowVote &&
                          "bg-red-600",
                        vote === "abstain" &&
                          soloSettings.managerShowVote &&
                          "bg-yellow-300",
                        vote === "absent" &&
                          soloSettings.managerShowVote &&
                          "bg-gray-600",
                        vote !== "none" &&
                          !soloSettings.managerShowVote &&
                          "bg-blue-600",
                        vote === "none" && "bg-gray-400",
                        "h-16 w-16 2xl:w-24 2xl:h-24 hd:h-32 hd:w-32 inline-flex items-center justify-center rounded-md cursor-pointer"
                      )}
                      key={index}
                      onClick={() => handleEnableManual(voter)}
                    >
                      <img
                        className="h-12 w-12 2xl:w-20 2xl:h-20 hd:h-28 hd:w-28 rounded-md"
                        src={voter.photoUrl}
                        alt=""
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        )}
        {user?.adminRole && (
          <div className="grid grid-cols-8 hd:grid-cols-6 gap-2 2xl:gap-2 2xl:px-8">
            {voters.map((voter, index) => {
              if (voter.loggedIn) {
                let vote = "none";
                // check the submitted votes to see if the voter id exists in the array
                let exists = submittedVotes.findIndex(
                  (e) => e.member === voter.id
                );
                if (exists >= 0) {
                  vote = submittedVotes[exists].vote;
                }
                return (
                  <div
                    className={clsx(
                      vote === "yea" &&
                        soloSettings.voteColor &&
                        "bg-green-600",
                      vote === "nay" && soloSettings.voteColor && "bg-red-600",
                      vote === "abstain" &&
                        soloSettings.voteColor &&
                        "bg-yellow-300",
                      vote === "absent" &&
                        soloSettings.voteColor &&
                        "bg-gray-600",
                      vote !== "none" &&
                        !soloSettings.voteColor &&
                        "bg-blue-600",
                      vote === "none" && "bg-gray-400",
                      "h-16 w-16 2xl:w-24 2xl:h-24 hd:h-32 hd:w-32 inline-flex items-center justify-center rounded-md"
                    )}
                    key={index}
                  >
                    <img
                      className="h-12 w-12 2xl:w-20 2xl:h-20 hd:h-28 hd:w-28 rounded-md"
                      src={voter.photoUrl}
                      alt=""
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
        <AnimatePresence>
          {manualVote && (
            <motion.div
              className="fixed z-10 inset-0 overflow-hidden"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="flex flex-col min-h-screen pt-4 px-4 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-coolGray-900 bg-opacity-75 transition-opacity"></div>
                {/* Start Modal */}
                <div className="inline-block bg-coolGray-700 rounded-lg text-left transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:px-6 sm:pt-2 sm:pb-8">
                  <div className="mt-2 text-center">
                    <h3 className="text-xl leading-6 font-medium text-blueGray-200">
                      <span className="text-zinc-300 mr-2">
                        Manual Vote For:
                      </span>{" "}
                      {manualId.name}
                    </h3>
                  </div>

                  <div className="items-center justify-center flex flex-nowrap w-full mt-6">
                    <button
                      className={`mt-3 mr-3 h-24 2xl:h-32 w-full inline-flex justify-center items-center rounded-full border border-transparent px-4 py-2 bg-green-600 font-medium text-gray-800 hover:bg-green-700 hover:text-white text-2xl 2xl:text-4xl`}
                      onClick={() => {
                        handleManualVote("yea");
                      }}
                    >
                      Yea
                    </button>
                    <button
                      className={`mt-3 mr-3 h-24 2xl:h-32 w-full inline-flex justify-center items-center rounded-full border border-transparent px-4 py-2 bg-red-600 font-medium text-gray-800 hover:bg-red-700 hover:text-white text-2xl 2xl:text-4xl`}
                      onClick={() => {
                        handleManualVote("nay");
                      }}
                    >
                      Nay
                    </button>
                    <button
                      className={`mt-3 mr-3 h-24 2xl:h-32 w-full inline-flex justify-center items-center rounded-full border border-transparent px-4 py-2 bg-yellow-300 font-medium text-gray-800 hover:text-white hover:bg-yellow-400 text-2xl 2xl:text-4xl`}
                      onClick={() => {
                        handleManualVote("abstain");
                      }}
                    >
                      Abstain
                    </button>
                    <button
                      className={`mt-3 h-24 2xl:h-32 w-full inline-flex justify-center items-center rounded-full border border-transparent px-4 py-2 bg-gray-600 font-medium text-gray-200 hover:bg-opacity-70 text-2xl 2xl:text-4xl`}
                      onClick={() => {
                        handleManualVote("absent");
                      }}
                    >
                      Absent
                    </button>
                  </div>
                </div>
                {/* End Modal */}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        {user?.votingRole && (
          <div className="items-center justify-center flex flex-nowrap w-full space-x-2 2xl:space-x-4 mt-3">
            <button
              className={`h-20 2xl:h-32 w-full inline-flex justify-center items-center rounded-full border border-transparent px-4 py-2 font-medium hover:bg-green-700 hover:text-white text-2xl 2xl:text-4xl
              ${buttonFb === "none" && "bg-green-600 text-gray-800"}
              ${
                buttonFb === "yea" &&
                "focus:outline-none ring-4 ring-offset-2 ring-indigo-500 bg-gray-400 text-white"
              } `}
              onClick={() => {
                handleVote("yea");
              }}
            >
              Yea
            </button>
            <button
              className={`h-20 2xl:h-32 w-full inline-flex justify-center items-center rounded-full border border-transparent px-4 py-2 font-medium hover:bg-red-700 hover:text-white text-2xl 2xl:text-4xl
              ${buttonFb === "none" && "bg-red-600 text-gray-800"}
              ${
                buttonFb === "nay" &&
                "focus:outline-none ring-4 ring-offset-2 ring-indigo-500 bg-gray-400 text-white"
              }`}
              onClick={() => {
                handleVote("nay");
              }}
            >
              Nay
            </button>
            {soloSettings.showAbstain && (
              <button
                className={`h-20 2xl:h-32 w-full inline-flex justify-center items-center rounded-full border border-transparent px-4 py-2 font-medium hover:text-white hover:bg-yellow-400 text-2xl 2xl:text-4xl
              ${buttonFb === "none" && "bg-yellow-300 text-gray-800"}
              ${
                buttonFb === "abstain" &&
                "focus:outline-none ring-4 ring-offset-2 ring-indigo-500 bg-gray-400 text-white"
              }`}
                onClick={() => {
                  handleVote("abstain");
                }}
              >
                Abstain
              </button>
            )}
            {/* TIE VOTE NO VOTE */}
            {boardDetails?.tieBreakerVote && user?.adminRole && (
              <button
                className={`h-20 xl:h-32 w-full inline-flex justify-center items-center rounded-full border border-transparent px-4 py-2 font-medium hover:bg-gray-700 hover:text-white text-2xl 2xl:text-4xl bg-gray-500 text-neutral-200
                ${
                  buttonFb === "recuse" &&
                  "focus:outline-none ring-4 ring-offset-2 ring-indigo-500 bg-gray-400 text-white"
                } `}
                onClick={() => {
                  handleVote("recuse");
                }}
              >
                No Vote
              </button>
            )}
          </div>
        )}
        {user?.managerRole && !enableEnd && (
          <div className="absolute inset-x-0 bottom-0 flex justify-center items-center px-10 bg-blue-800 bg-opacity-90 p-2 rounded-md">
            <button
              type="button"
              className="px-6 py-4 2xl:px-8 2xl:py-6 rounded-md shadow-sm text-xl 2xl:text-2xl font-semibold text-white text-center bg-green-600 hover:bg-green-800 focus:outline-none transform duration-300"
              onClick={() =>
                sendEvent("voteAllYes", {
                  motion: activeMotion,
                  topic: activeTopic,
                  voters,
                  tieBreak: boardDetails?.tieBreakerVote,
                })
              }
            >
              Vote All Yes
            </button>
          </div>
        )}
        {(user?.adminRole || user?.managerRole) && enableEnd && (
          <div className="absolute inset-x-0 bottom-0 flex justify-center items-center px-10 bg-blue-800 bg-opacity-90 p-2 rounded-md">
            <button
              type="button"
              className="px-6 py-4 2xl:px-8 2xl:py-6 border border-transparent rounded-md shadow-sm text-xl 2xl:text-2xl font-semibold text-white text-center bg-red-600 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={() =>
                sendEvent("voteEnded", {
                  motion: activeMotion,
                  topic: activeTopic,
                  testMode,
                  votingOption: voteWeight.id,
                  board: sessionBoard,
                })
              }
            >
              End Vote
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default VoteModal;
