import React from "react";

import { IoIosOptions } from "react-icons/io";
import { HiUsers, HiOutlineDocumentText } from "react-icons/hi";
import { AiOutlineSchedule, AiOutlineHistory } from "react-icons/ai";
import { BiLink, BiPlug } from "react-icons/bi";
import { FaRegComments } from "react-icons/fa";

const icons = [
  <HiUsers className="mr-3 h-8 w-5 2xl:h-12 2xl:w-6 text-gray-300" />,
  <AiOutlineSchedule className="mr-3 h-8 w-5 2xl:h-12 2xl:w-6 text-gray-300" />,
  <AiOutlineHistory className="mr-3 h-8 w-5 2xl:h-12 2xl:w-6 text-gray-300" />,
  <IoIosOptions className="mr-3 h-8 w-5 2xl:h-12 2xl:w-6 text-gray-300" />,
  <BiLink className="mr-3 h-8 w-5 2xl:h-12 2xl:w-6 text-gray-300" />,
  <BiPlug className="mr-3 h-8 w-5 2xl:h-12 2xl:w-6 text-gray-300" />,
  <HiOutlineDocumentText className="mr-3 h-8 w-5 2xl:h-12 2xl:w-6 text-gray-300" />,
  <FaRegComments className="mr-3 h-8 w-5 2xl:h-12 2xl:w-6 text-gray-300" />,
];

const MenuItem = ({ name, status, index, choose }) => {
  if (status) {
    return (
      <div
        className="group flex items-center px-2 py-2 text-base 2xl:text-lg font-medium text-white rounded-md bg-gray-900"
        onClick={() => choose(index)}
      >
        {icons[index]}

        {name}
      </div>
    );
  } else {
    return (
      <div
        className="group flex items-center px-2 py-2 text-base 2xl:text-lg font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 cursor-pointer"
        onClick={() => choose(index)}
      >
        {icons[index]}
        {name}
      </div>
    );
  }
};

export default MenuItem;
